import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  patchData,
  postData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}
interface Params {
  [key: string]: string | number | boolean
}
const fetchOrderData = async (input: any) => {
  const { type, ...rest } = input
  const url = buildUrlWithParams(apiUrl.ORDER_LIST_URL, {
    ...rest,

    ...(type !== 'all' ? { order_status__id: type } : {}),
  })
  const response = await getData(url)
  return response?.data
}

export const useByOrder = ({ input, type }: { input: any; type?: string }) => {
  return useQuery(['by_order', input, type], () => fetchOrderData(input), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
export const deleteOrder = (orderId?: string) => {
  return deleteData(`${apiUrl.ORDER_LIST_URL}${orderId}`)
}
export const deleteOrderItem = (orderId?: string) => {
  return deleteData(`${apiUrl.ORDER_LIST_URL}${orderId}/delete`)
}
export const cancelOrder = (orderId?: string) => {
  return patchData(`${apiUrl.ORDER_LIST_URL}${orderId}/cancel`, {})
}
export const restoreOrder = (orderId?: string) => {
  return patchData(`${apiUrl.ORDER_LIST_URL}${orderId}/restore`, {})
}
export const { getOrderStatusList, getServiceCategoryList, getAllServiceList } =
  {
    getOrderStatusList: (params: Params) =>
      getData(buildUrlWithParams(apiUrl.ORDER_STATUS, params)),
    getServiceCategoryList: (params: Params) =>
      getData(buildUrlWithParams(apiUrl.SERVICE_CATEGORY, params)),
    getAllServiceList: (params: Params) =>
      getData(buildUrlWithParams(apiUrl.SERVICE_LIST_URL + 'list/', params)),
  }

export const getOrderDetails = (id: any) => {
  return getData(`${apiUrl.ORDER_LIST_URL}${id}`)
}

export const getOrderInvoiceList = (id: any) => {
  return getData(`${apiUrl.INVOICE_LIST_URL}?order_id=${id}`)
}

export const getOrderInvoice = (id: any) => {
  return getData(`${apiUrl.INVOICE_LIST_URL}/${id}`)
}

export const generateInvoice = (id: string, data: any) => {
  return postData(`${apiUrl.ORDER_LIST_URL}${id}/invoices`, data)
}
export const chaneOrderLock = (id: string, data: any) => {
  return postData(`${apiUrl.ORDER_LIST_URL}${id}/change-lock-status`, data)
}

export const deleteInvoice = (id: string, reason: string) => {
  return deleteData(`invoices?id=${id}&cancellation_reason=${reason}`)
}

export const getOrderServiceDetails = async (
  id: string,
  input: QueryParams
) => {
  const url = buildUrlWithParams(`${apiUrl.ORDER_LIST_URL}${id}/items`, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const useOrderServiceDetails = (id: string, input: any) => {
  return useQuery(
    ['order_service_list', id, input],
    () => getOrderServiceDetails(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

export const serviceAllocated = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.SERVICE_ALLOCATED}`, data?.input)
}

export const useServiceAllocated = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(serviceAllocated, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Service Allocated successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data.errors ??
            error.response.data.error.message ??
            error.response.data.errors[0].message[0]
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const addService = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.ORDER_LIST_URL}${data?.id}/add-items`, data?.input)
}

export const useAddService = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(addService, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Service Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data.errors[0].message[0] ?? error.response.data.errors
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const getLeadSource = (data: any) => {
  return getData(`${apiUrl.LEAD_SOURCE}${parseQueryParams(data)}`)
}

export const getContacts = (id: any, data?: any) => {
  return getData(
    `${apiUrl.CONTACT_LIST_URL}account/${id}/contacts/${parseQueryParams(data)}`
  )
}

const orderPaymentFetchData = async (id: string, input?: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.ORDER_LIST_URL}${id}/payments`, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const useOrderPayment = (id: string, input?: QueryParams) => {
  return useQuery(
    ['order-payment', id, input],
    () => orderPaymentFetchData(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

export const addPayment = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.ORDER_LIST_URL}${data?.id}/payments`, data?.input)
}
export const deletePayment = async (id?: string) => {
  return deleteData(`${apiUrl.PAYMENT_LIST}${id}`)
}

export const useAddPayment = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(addPayment, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Payment Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data.error.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const editOrder = (data: { input: any; id: string }) => {
  return updateData(`${apiUrl.ORDER_LIST_URL}${data?.id}`, data?.input)
}
export const useEditOrder = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editOrder, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Order Edited successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const add_orders = (val: any) =>
  postData(`${apiUrl.ORDER_LIST_URL}create-order`, val)

export const sendInvoice = (id: string, data: any) => {
  return postData(`${apiUrl.ORDER_LIST_URL}${id}/send-invoices`, data)
}
export const assignTeam = (orderId: string, serviceId: string, data: any) => {
  return postData(
    `${apiUrl.ORDER_LIST_URL}${orderId}/items/${serviceId}/assign-team`,
    data
  )
}
export const deleteOrderUnallocatedService = (
  orderId: any,
  service_id: any
) => {
  return deleteData(
    `${apiUrl.ORDER_LIST_URL}${orderId}/remove-item/${service_id}`
  )
}
export const cancelService = (serviceId?: string) => {
  return patchData(`${apiUrl.ORDER_SERVICES_URL}/${serviceId}/cancel`, {})
}
export const restoreService = (serviceId?: string) => {
  return patchData(`${apiUrl.ORDER_SERVICES_URL}/${serviceId}/restore`, {})
}
export const getFranchiseePaymentGateway = (id?: string) => {
  return getData(`${apiUrl.VENDOR_DETAILS}${id}/payment-gateway-details`)
}
export const getVendorPaymentGateway = (id?: string) => {
  return getData(`${apiUrl.VENDOR_URL}${id}/payment-gateway-details`)
}
export const sendPaymentLink = async (id?: string) => {
  return patchData(`${apiUrl.PAYMENT_LIST}${id}/send_payment_link`, {})
}
