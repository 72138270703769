import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
// import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import {
  fetchVendorBankAccount,
  getPaymentOutList,
  getPaymentOutMethods,
} from '../../../../../apis/common.apis'
import FormBuilder from '../../../../../components/app/formBuilder'
import CustomDrawer from '../../../../../components/common/drawer'
import { useSnackbarManager } from '../../../../../components/common/snackbar'
import { useAuthStore } from '../../../../../store/authStore'
import { getErrorMessage } from '../../../../../utilities/parsers'
import { deletePayoutFile } from '../../../api'
import { addServicePayout } from '../api'
import { ACCEPTED_IMAGE_TYPES, handleSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback: () => void
  data?: any
}

export default function AddPayout({
  isDrawerOpen,
  handleClose,
  handleCallback,
  data,
}: Props) {
  const [method, setMethod] = useState()
  const vendor = useAuthStore.getState().userData.vendor
  const [bankAccounts, setBankAccounts] = useState<any>([])
  const paymentSchema = handleSchema(method, data)
  type PaymentSchema = z.infer<typeof paymentSchema>
  const [payoutType, setPayoutType] = useState<any>([])
  const [payoutMethod, setPayoutMethod] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()
  const { userData } = useAuthStore()

  const getBankAccountList = () => {
    fetchVendorBankAccount({
      id: vendor as string,
      input: { page: 1, page_size: 30 },
    }).then((res) => {
      setBankAccounts(res?.data?.results)
    })
  }
  const methods = useForm<PaymentSchema>({
    resolver: zodResolver(paymentSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  })

  useEffect(() => {
    getPayoutList()
    getBankAccountList()
    getPayoutMethodsList()
    if (isDrawerOpen) {
      methods.reset({
        payment_date: moment().format('YYYY-MM-DD'),
        amount: data?.amount ? parseFloat(data?.amount) : 0,
        bizpole_payable_amount: data?.bizpole_payable_amount
          ? parseFloat(data?.bizpole_payable_amount)
          : 0,
      })
    }
  }, [data, isDrawerOpen])

  useEffect(() => {
    extractAccount(data?.account_name)
  }, [bankAccounts])

  const extractAccount = (account_name: string) => {
    bankAccounts.map((item: any) => {
      if (item.account_name == account_name) {
        methods.reset({
          ...methods.watch(),
          bank_account_id: item.id,
          bank_account_name: item.account_display_name
            ? item.account_display_name
            : item.account_name,
        })
      }
    })
  }

  const getPayoutMethodsList = async () => {
    await getPaymentOutMethods({ is_active: true }).then((res) => {
      setPayoutMethod(res?.data)
    })
  }
  const getPayoutList = async () => {
    await getPaymentOutList({ show_in_vendor: true }).then((res) => {
      setPayoutType(res?.data)
    })
  }
  const handleDeleteFile = () => {
    deletePayoutFile(data?.id)
      .then(() => {
        enqueueSnackbar('File removed successfully', { variant: 'success' })
        handleCallback?.()
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, { variant: 'error' })
      })
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    type = 'text'
    // disabled = false
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    // ...(disabled ? { disabled: true } : {}),
  })
  const paymentMethodCallback = (d: any) => {
    setMethod(d.code)
    if (d.code === 'cash') {
      methods.clearErrors('bank_account_id')
      methods.clearErrors('bank_account_name')
    }
  }
  const formBuilderProps = [
    {
      name: 'paymentout_type_name',
      label: 'Payment Type',
      required: true,
      data: payoutType,
      async: false,
      id: 'paymentout_type',

      descId: 'id',
      // initialLoad: true,
      desc: 'name',
      type: 'custom_select',
      placeholder: 'Enter Payment Type',
    },
    {
      ...textField(
        'payment_date',
        'Date of Payment',
        'Enter transaction date',
        true,
        'date'
      ),
      maxDate: new Date(),
      type: 'date',
    },
    {
      ...textField('amount', 'Amount', 'Amount', true, 'number'),
      type: 'number',
      disabled: true,
    },
    {
      ...textField(
        'bizpole_payable_amount',
        'Bizpole Payable Amount',
        'Bizpole Payable Amount'
      ),
      type: 'text',
      disabled: true,
    },
    {
      name: 'paymentout_method_name',
      label: 'Mode of Payment',
      handleCallBack: paymentMethodCallback,
      data: payoutMethod,
      required: true,
      id: 'paymentout_method_id',

      descId: 'id',
      // initialLoad: true,

      desc: 'name',
      type: 'custom_select',
      placeholder: 'Enter Mode of Payment',
    },
    {
      ...textField(
        'transaction_number',
        'Transaction number',
        'Enter Transaction number',
        true,
        'text'
      ),
    },
    {
      ...textField(
        'receipt_number',
        'Receipt number',
        'Enter Receipt number',
        true,
        'text'
      ),
    },
    {
      name: 'bank_account_name',
      label: 'Bank Account',

      data: bankAccounts,
      required:
        method === 'cash' || data?.paymentout_method?.code === 'cash'
          ? false
          : true,
      id: 'bank_account_id',

      descId: 'id',
      // initialLoad: true,

      desc: 'account_display_name',
      type: 'custom_select',
      placeholder: 'Enter bank account',
    },
    {
      ...textField('remark', 'Remarks', 'Enter Remarks', false),
      type: 'textarea',
      value: data?.remark,
    },

    {
      name: 'payment_document',
      required: false,
      id: 'payment_document',
      descId: 'id',
      selectedFiles: data?.original_document
        ? { name: data?.original_document, link: data?.payment_document }
        : '',
      handleDeleteFile: handleDeleteFile,
      type: 'file_upload',
      label: 'Payment Document',
      acceptedFiles: 'JPEG,JPG,PNG,PDF',
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      supportedFiles: ACCEPTED_IMAGE_TYPES,
      link: data?.payment_document,
      value: data?.original_document,
    },
  ]

  const handleSubmission = () => {
    methods.reset({})
    handleCallback()
    handleClose()
  }

  const onSubmit = (form_data: PaymentSchema) => {
    setIsLoading(true)
    const input: any = {
      amount: form_data?.amount ?? '',
      paymentout_method: form_data?.paymentout_method_id ?? '',
      payment_date: form_data?.payment_date
        ? moment(form_data?.payment_date).format('YYYY-MM-DD')
        : '',
      transaction_number: form_data?.transaction_number ?? '',
      paymentout_type: form_data?.paymentout_type ?? '',
      receipt_number: form_data?.receipt_number ?? '',
      bank_account_id: form_data?.bank_account_id ?? '',
      remark: form_data?.remark ?? '',
      vendor: userData?.vendor,
      ...(form_data?.payment_document
        ? { payment_document: form_data?.payment_document }
        : {}),
    }
    const payload = new FormData()
    Object.keys(input).forEach((k) => {
      payload.append(k, input[k])
    })

    const payloadData = {
      orderId: data?.service?.order?.id,
      serviceId: data?.service?.id,
      paymentRequestId: data?.id,
      data: payload,
    }
    addServicePayout(payloadData)
      .then(() => {
        enqueueSnackbar(
          getErrorMessage('Payment request mark as paid and payout generated'),
          {
            variant: 'success',
          }
        )
        handleSubmission()
        setIsLoading(false)
      })
      .catch((err: any) => {
        enqueueSnackbar(getErrorMessage(err.response.data.error), {
          variant: 'error',
        })
        setIsLoading(false)
      })
  }

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      actionLoader={isLoading}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      title={'Payment Out'}
      disableSubmit={isLoading}
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder data={formBuilderProps} edit={true} />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
